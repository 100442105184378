<template>
  <main class="app-container">
    <div class="home-banner"></div>
    <div class="search-container w1200">
      <div class="slogan-box">
        <p>“安全直发专线物流”</p>
      </div>
      <div class="search-box">
        <p class="search-title">运单查询</p>
        <div class="search-form">
          <input
            type="text"
            v-model="waybillNo"
            placeholder="请输入单号查询订单"
            @keypress.enter="onSearch"
          />
          <div class="search-btn">
            <button class="query" @click="onSearch">立即查询</button>
            <button class="register">
              <a
                href="https://www.lmparcel.com/lmcustomerlogin/login"
                target="_blank"
                >注册发货</a
              >
            </button>
          </div>
        </div>
        <p class="slogan-text">
          六脉小包是九方通逊旗下倾力打造的直发类专线物流品牌，致力于为独立站、平台卖家、品牌出海客户提供稳定、快捷、高性价比的B2C包裹直发物流解决方案。
        </p>
      </div>
    </div>

    <div class="product-intro w1200">
      <div class="intro-title">
        <span>产品板块</span>
      </div>
      <div class="intro-list">
        <div class="intro-list-item">
          <div class="item-pic">
            <img
              src="../../assets/images/home/product/us-pro-desc.png"
              alt=""
              class="item-desc-img"
            />
            <img
              src="../../assets/images/home/product/us-flag.png"
              alt=""
              class="item-country-img"
            />
          </div>
          <div class="item-desc">
            <p class="item-desc-title">美国专线</p>
            <p class="item-desc-time">全程时效7-13个工作日</p>
            <p class="item-desc-text">
              经济实惠预清关，USPS派送；天天固定优质航班
            </p>
            <router-link tag="p" class="item-desc-btn" to="/product"
              >查看详情</router-link
            >
          </div>
        </div>
        <div class="intro-list-item">
          <div class="item-pic">
            <img
              src="../../assets/images/home/product/uk-pro-desc.png"
              alt=""
              class="item-desc-img"
            />
            <img
              style="left: -2px"
              src="../../assets/images/home/product/uk-flag.png"
              alt=""
              class="item-country-img"
            />
          </div>
          <div class="item-desc">
            <p class="item-desc-title">英国专线</p>
            <p class="item-desc-time">全程时效4-7个工作日</p>
            <p class="item-desc-text">
              性价比高，多口岸直飞清关，本土优势尾程组合派送
            </p>
            <router-link tag="p" class="item-desc-btn" to="/product"
              >查看详情</router-link
            >
          </div>
        </div>
        <div class="intro-list-item">
          <div class="item-pic">
            <img
              src="../../assets/images/home/product/ge-pro-desc.png"
              alt=""
              class="item-desc-img"
            />
            <img
              src="../../assets/images/home/product/ge-flag.png"
              alt=""
              class="item-country-img"
            />
          </div>
          <div class="item-desc">
            <p class="item-desc-title">德国专线</p>
            <p class="item-desc-time">全程时效5-7个工作日</p>
            <p class="item-desc-text">固定舱位，时效稳定<br />尾程DHL派送</p>
            <router-link tag="p" class="item-desc-btn" to="/product"
              >查看详情</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="advantage-board">
      <div class="advantage-container">
        <p class="advantage-title">
          <span class="brand-name">六脉速运</span>
          <span class="brand-slogan">安全直发专线物流</span>
        </p>
        <div class="advantage-list w1200">
          <div class="advantage-item">
            <div class="advantage-service">
              <span class="service-num" content="单">5W</span>
              <p class="advantage-service-text">日均处理单量</p>
            </div>
          </div>
          <div class="advantage-item">
            <div class="advantage-service">
              <span class="service-num" content="个">22</span>
              <p class="advantage-service-text">全球网点</p>
            </div>
          </div>
          <div class="advantage-item">
            <div class="advantage-service text-right">
              <span class="service-num" content="个">1200+</span>
              <p class="advantage-service-text">服务客户数</p>
            </div>
          </div>
        </div>
        <div class="service-container w1200">
          <div class="service-item">
            <div class="air service-bg">
              <img
                src="../../assets/images/home/advantage/air-icon.png"
                class="service-item-icon"
              />
              <p class="service-item-cn">头程直飞</p>
              <p class="service-item-en">The first direct flight</p>
            </div>
          </div>
          <div class="service-item">
            <div class="charge service-bg">
              <img
                src="../../assets/images/home/advantage/charge-icon.png"
                class="service-item-icon"
              />
              <p class="service-item-cn">可带电运输</p>
              <p class="service-item-en">
                Can be transported <br />with electricity
              </p>
            </div>
          </div>
          <div class="service-item">
            <div class="packing service-bg">
              <img
                src="../../assets/images/home/advantage/packing-icon.png"
                class="service-item-icon"
              />
              <p class="service-item-cn">退件重派服务</p>
              <p class="service-item-en">
                Return redispatch <br />
                service
              </p>
            </div>
          </div>
          <div class="service-item">
            <div class="delivery service-bg">
              <img
                src="../../assets/images/home/advantage/delivery-icon.png"
                class="service-item-icon"
              />
              <p class="service-item-cn">配送覆盖美英德</p>
              <p class="service-item-en">
                The delivery covers <br />
                the United States, <br />
                Britain and Germany
              </p>
            </div>
          </div>
          <div class="service-item">
            <div class="track service-bg">
              <img
                src="../../assets/images/home/advantage/track-icon.png"
                class="service-item-icon"
              />
              <p class="service-item-cn">支持全轨迹查询</p>
              <p class="service-item-en">Full trajectory query is supported</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="partner-container w1200">
      <div class="partner-title">合作伙伴</div>
      <el-carousel
        class="partner-carousel"
        height="208px"
        arrow="never"
        :interval="6000"
        indicator-position="outside"
      >
        <el-carousel-item v-for="(item, index) in partnerList" :key="index">
          <div class="partner-list">
            <a
              :href="subItem.href"
              target="_blank"
              class="partner-item"
              v-for="(subItem, subIndex) in item"
              :key="subIndex"
              :style="{'background-color':subItem.bgc||'#fff'}"
            >
              <img :src="subItem.url" alt="" />
            </a>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <SixTrack></SixTrack> -->
  </main>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      partnerList: [
        [
          {
            url: require("@/assets/images/home/partner/amazon.png"),
            href: "https://www.amazon.cn/",
          },
          {
            url: require("@/assets/images/home/partner/cdiscount.png"),
            href: "https://www.cdiscount.com/",
          },
          {
            url: require("@/assets/images/home/partner/temu.png"),
            href: "https://www.temu.com/",
            bgc: '#fb7701'
          },
          {
            url: require("@/assets/images/home/partner/cainiao.png"),
            href: "https://www.cainiao.com/",
          },
          {
            url: require("@/assets/images/home/partner/shein.png"),
            href: "https://zhaoshang.sheincorp.cn/",
          },
          {
            url: require("@/assets/images/home/partner/sailvan.png"),
            href: "https://www.sailvan.com/",
          },
          {
            url: require("@/assets/images/home/partner/ugreen.png"),
            href: "https://www.lulian.cn/",
          },
          {
            url: require("@/assets/images/home/partner/zhiou.png"),
            href: "https://www.songmicshomegroup.com/",
          },
          {
            url: require("@/assets/images/home/partner/mabang.png"),
            href: "https://www.mabangerp.com/",
          },
          {
            url: require("@/assets/images/home/partner/eccang.png"),
            href: "https://www.ectmswms.com/",
          },
        ],
        [
          {
            url: require("@/assets/images/home/partner/dianxiaomi.png"),
            href: "https://www.dianxiaomi.com/",
          },
          {
            url: require("@/assets/images/home/partner/lrobotbox.png"),
            href: "https://www.irobotbox.com/",
          },
          {
            url: require("@/assets/images/home/partner/mangoerp.png"),
            href: "https://www.mangoerp.com/",
          },
          {
            url: require("@/assets/images/home/partner/tongtool.png"),
            href: "https://www.tongtool.com/",
          },
        ],
      ],
      waybillNo: "",
    };
  },
  mounted() {},
  methods: {
    onSearch() {
      const len = this.waybillNo.length < 6 || this.waybillNo.length == 0;
      if (len) {
        return this.$message.error(`单号有误，请输入正确的单号`);
      }
      this.$router.push({
        path: "/search",
        query: { nums: this.waybillNo },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home-banner {
  height: 525px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../../assets/images/home/banner.jpg);
}
.search-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -134px;
  .slogan-box {
    width: 282px;
    height: 212px;
    background-image: url("../../assets/images/home/search-slogan.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    p {
      margin-top: 50px;
      text-align: center;
      font-size: 18px;
      font-family: SourceHanSansCN-Bold;
      color: #ffffff;
    }
  }
  .search-box {
    width: 919px;
    height: 268px;
    background: linear-gradient(
      85deg,
      rgba(223, 235, 255, 0.75) 0%,
      rgba(255, 255, 255, 0.75) 13%,
      rgba(255, 255, 255, 0.75) 100%
    );
    box-shadow: 0px 0px 24px 0px rgba(97, 97, 97, 0.36);
    box-sizing: border-box;
    padding: 46px 83px;
    .search-title {
      font-size: 18px;
      font-family: SourceHanSansCN-Bold;
      color: #2a2a2a;
      margin-bottom: 20px;
    }
    .search-form {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 25px;
      input {
        width: 378px;
        height: 57px;
        background: #ffffff;
        border: 1px solid #6c6c6c;
        margin-right: 28px;
        padding-left: 18px;
      }
      .search-btn {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1;
        button {
          cursor: pointer;
          width: 168px;
          height: 56px;
          font-size: 18px;
          font-family: SourceHanSansCN-Bold;
          color: #ffffff;
          line-height: 56px;
          border: none;
          &.query {
            background: #e2385c;
          }
          &.register {
            background: #457fda;
            a {
              display: block;
              height: 100%;
              width: 100%;
              color: #fff;
            }
          }
        }
      }
    }
    .slogan-text {
      font-size: 14px;
      font-weight: 400;
      color: #6c6c6c;
      line-height: 26px;
    }
  }
}

.advantage-title,
.intro-title,
.partner-title {
  font-size: 34px;
  line-height: 57px;
  margin-bottom: 30px;
  text-align: center;
  position: relative;
  color: #e2385c;
  .brand-name {
    color: #4d80d9;
    margin-right: 10px;
  }
  .brand-slogan {
    color: #e2385c;
  }
  &::before {
    display: block;
    height: 48px;
    position: absolute;
    color: #457fda;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateX(-50%);
    top: -59%;
    left: 50%;
    content: "";
  }
}
.advantage-title::before {
  width: 765px;
  background-image: url(../../assets/images/home/advantage/advantage-title.png);
}
.intro-title::before {
  width: 312px;
  background-image: url(../../assets/images/home/product/product-title.png);
}
.partner-title::before {
  width: 190px;
  background-image: url(../../assets/images/home/partner/partner-title.png);
}

.product-intro {
  margin-top: 100px;
  .intro-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &-item {
      width: 371px;
      height: 542px;
      background: #ffffff;
      border: 1px solid #eee;
      &:hover {
        box-shadow: 0px 6px 7px 0px rgba(186, 197, 217, 0.52);

        .item-desc-btn {
          background-color: #2065da;
        }
      }
      .item-pic {
        width: 100%;
        height: 265px;
        position: relative;
        .item-desc-img {
          width: 100%;
          height: 100%;
        }
        .item-country-img {
          width: 85px;
          height: 59px;
          position: absolute;
          left: -1px;
          bottom: 0;
          transform: translateY(50%);
        }
      }
      .item-desc {
        text-align: center;
        padding: 0 54px;
        box-sizing: border-box;
        &-title {
          margin-top: 40px;
          font-size: 18px;
          color: #000000;
        }
        &-time {
          margin-top: 7px;
          font-size: 16px;
          font-weight: 500;
          color: #e2385c;
        }
        &-text {
          font-size: 14px;
          color: #000000;
          line-height: 25px;
          margin-top: 12px;
        }
        &-btn {
          width: 116px;
          height: 37px;
          font-size: 14px;
          color: #ffffff;
          line-height: 37px;
          margin: 16px auto 0;
          cursor: pointer;
          background-color: #7a8aa4;
        }
      }
    }
  }
}

.advantage-board {
  box-sizing: border-box;
  margin-top: 100px;
}
.advantage-container {
  width: 100%;
  box-sizing: border-box;
  padding: 130px 0;
  background-image: url(../../assets/images/home/advantage/bg.png);
  height: 1110px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .advantage-list {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .advantage-item {
      width: 343px;
      height: 84px;
      .service-num {
        font-size: 70px;
        font-family: Roboto;
        font-weight: 400;
        color: #030303;
        position: relative;
        line-height: 60px;
        &::after {
          position: absolute;
          right: -45px;
          top: 18px;
          display: block;
          content: attr(content);
          width: 37px;
          height: 37px;
          background: #e2385c;
          border-radius: 50%;
          text-align: center;
          color: #fff;
          text-align: center;
          line-height: 37px;
          font-size: 20px;
        }
      }

      &:not(:last-child) {
        border-right: 2px dashed #767676;
      }
      &:nth-child(2) {
        text-align: center;
        .advantage-service-text,
        .service-num {
          color: #e2385c;
        }
      }
      &:nth-child(3) {
        .advantage-service-text {
          text-align: justify;
          text-indent: 145px;
        }
      }
      .advantage-service-text {
        font-size: 20px;
        color: #030303;
        line-height: 22px;
        margin-top: 8px;
      }
    }
  }
  .service-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 120px;
    .service-item {
      width: 202px;
      height: 333px;
      border: 1px solid #5b5b5b;
      text-align: center;
      position: relative;
      background: linear-gradient(0deg, #457fda 0%, #0e4fbc 100%);
      &::after {
        content: "";
        display: block;
        width: 81px;
        height: 3px;
        background: #fff;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:hover {
        transition: all 0.3;
        &::after {
          background-color: #e2385c;
        }

        .air {
          position: absolute;
          background-image: url(../../assets/images/home/advantage/air-bg.png);
        }
        .charge {
          position: absolute;
          background-image: url(../../assets/images/home/advantage/charge-bg.png);
        }
        .packing {
          position: absolute;
          background-image: url(../../assets/images/home/advantage/packing-bg.png);
        }
        .delivery {
          position: absolute;
          background-image: url(../../assets/images/home/advantage/delivery-bg.png);
        }
        .track {
          position: absolute;
          background-image: url(../../assets/images/home/advantage/track-bg.png);
        }
      }
      & > .service-bg {
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        background-color: transparent;
        width: 100%;
        height: 100%;
      }
      .service-item-icon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 113px;
        height: 113px;
      }
      .service-item-cn {
        margin-top: 103px;
        font-size: 18px;
        color: #ffffff;
      }
      .service-item-en {
        margin-top: 30px;
        font-size: 14px;
        color: #ffffff;
        opacity: 0.5;
        line-height: 18px;
      }
    }
  }
}

.partner-carousel {
  ::v-deep .el-carousel__indicators {
    margin-top: 20px;
    .el-carousel__button {
      width: 10px;
      height: 10px;
      background: #c1c1c1;
      border-radius: 50%;
    }
    .is-active .el-carousel__button {
      background-color: #e2385c;
    }
  }
}
.partner-container {
  margin-bottom: 20px;
  margin-top: -45px;
}
.partner-list {
  height: 208px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: space-between;
}
.partner-item {
  display: block;
  width: 223px;
  height: 93px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  img {
    width: 100%;
    height: 100%;
  }
  &:not(:nth-child(5n)) {
    margin-right: 16px;
  }
}
</style>